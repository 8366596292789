import { createStore } from 'vuex'
import { Vue } from 'vue'
import ApiClient from '../lib/http.coffee';
import { useCookies } from 'vue3-cookies'

export default createStore({
  state: {
    token: null,
    errors: {},
    loginState: '',
    ShipperloginState: '',
    fcDisplayItems: {},
    fcStorehouseDisplayItems: {},
    storehouseDisplayItems: {},
    shipperDisplayItems: {},
    searchParam: {
      top: {}, // トップ画面
      warehousing: {}, // 入庫一覧
      warehousingPage: 1, // 入庫一覧
      adminStorehouse: {}, // 配送一覧
      stock: {}, // 在庫確認
      stockPage: 1, // 在庫確認
      loadingAndUnloading: {}, // 入出庫一覧
      loadingAndUnloadingPage: 1, // 入出庫一覧
      division: {}, // 事業部一覧
      divisionPage: 1, // 事業部一覧
      fcManagerPage: 1, // 担当者一覧
      fcManager: {}, // 担当者一覧
      storehouse: {}, // 倉庫一覧
      storehousePage: 1, // 倉庫一覧
      shipper: {}, // 荷主一覧
      shipperPage: 1, // 荷主一覧
      product: {}, // 商品一覧
      productPage: 1, // 商品一覧
      unit: {}, // 単位一覧
      unitPage: 1, // 単位一覧
      delivery: {}, // 納品先一覧
      deliveryPage: 1, // 納品先一覧
      transport: {}, // 運送一覧
      transportPage: 1, // 運送一覧
      truck: {}, // 車種一覧
      truckPage: 1, // 車種一覧
      remark: {}, // 備考一覧
      remarkPage: 1, // 備考一覧
      ledgerNumber: {}, // 台帳番号一覧
      ledgerNumberPage: 1, // 台帳番号一覧
    },
    shipperSearchParam: {
      top: {}, // トップ画面
      warehousing: {}, // 入庫一覧
      warehousingPage: 1, // 入庫一覧
      product: {}, // 商品一覧
      productPage: 1, // 商品一覧
      delivery: {}, // 納品先一覧
      deliveryPage: 1, // 納品先一覧
    },
    storehouseSearchParam: {
      top: {}, // トップ画面
      warehousing: {}, // 入庫一覧
      warehousingPage: 1, // 入庫一覧
      stock: {}, // 在庫確認
      stockPage: 1, // 在庫確認
    },
  },
  getters: {
    getToken(state) {
      if (!state.token) {
        const { cookies } = useCookies();
        if (!cookies.get('__jwt_token_admin')) {
          return '';
        }
        state.token = cookies.get('__jwt_token_admin');
      }
      return state.token;
    },
    getErrors(state) {
      return state.errors;
    },
    async getLoginState(state) {
      if (!state.loginState) {
        const api = new ApiClient();
        const result = await api.get('/login/state');
        state.loginState = result;
      }
      return state.loginState;
    },
    async getShipperLoginState(state) {
      if (!state.ShipperloginState) {
        const api = new ApiClient();
        const result = await api.get('/shipper/state');
        state.ShipperloginState = result;
      }
      return state.ShipperloginState;
    },
    async getStorehouseLoginState(state) {
      if (!state.loginState) {
        const api = new ApiClient();
        const result = await api.get('/storehouse/state');
        state.loginState = result;
      }
      return state.loginState;
    },
    defaultSetFcDisplayItems(state) {
      const { cookies } = useCookies();

      let fcDisplayItems = {
        status: true,
        inventory_shortage_flg: true,
        comment_flg: true,
        shipper: true,
        ledger_no: true,
        management_no: true,
        admin: true,
        account_flg: true,
        shipper_manager_name: true,
        input_date: true,
        input_time: true,
        request_no: true,
        vehicle_type: true,
        shipment_date: true,
        due_date: true,
        delivery_time: true,
        product_item_id: true,
        product_name: true,
        product_number: true,
        quantity: true,
        unit_name: true,
        warehouse_place: true,
        vehicle: true,
        delivery_address: true,
        consignee: true,
        contact_address: true,
        remarks: true,
        driver_flg: true,
        serial_flg: true,
        file_flg: true,
      }
      if (cookies.get('fcDisplayItems') == null || Object.keys(cookies.get('fcDisplayItems')).length != Object.keys(fcDisplayItems).length) {
        state.fcDisplayItems = fcDisplayItems
        cookies.set('fcDisplayItems', JSON.stringify(state.fcDisplayItems), '50y')
      }
      return cookies.get('fcDisplayItems')
    },
    defaultSetFcStorehouseDisplayItems(state) {
      const { cookies } = useCookies();

      let fcStorehouseDisplayItems = {
        inventory_shortage_flg: true,
        shipper: true,
        ledger_no: true,
        comment_flg: true,
        management_no: true,
        admin: true,
        shipment_no: true,
        vehicle_type: true,
        claim_date: true,
        delivery_time: true,
        product_name: true,
        product_number: true,
        quantity: true,
        unit_name: true,
        delivery_address: true,
        consignee: true,
        contact_address: true,
        remarks: true,
        driver_flg: true,
        serial_flg: true,
        file_flg: true,
      }

      if (cookies.get('fcStorehouseDisplayItems') == null || Object.keys(cookies.get('fcStorehouseDisplayItems')).length != Object.keys(fcStorehouseDisplayItems).length) {
        state.fcStorehouseDisplayItems = fcStorehouseDisplayItems
        cookies.set('fcStorehouseDisplayItems', JSON.stringify(state.fcStorehouseDisplayItems), '50y')
      }
      return cookies.get('fcStorehouseDisplayItems')
    },
    defaultSetStorehouseDisplayItems(state) {
      const { cookies } = useCookies();

      let storehouseDisplayItems = {
        inventory_shortage_flg: true,
        shipper: true,
        ledger_no: true,
        comment_flg: true,
        management_no: true,
        admin: true,
        shipment_no: true,
        vehicle_type: true,
        claim_date: true,
        delivery_time: true,
        product_name: true,
        product_number: true,
        quantity: true,
        unit_name: true,
        delivery_address: true,
        consignee: true,
        contact_address: true,
        remarks: true,
        driver_flg: true,
        serial_flg: true,
      }

      if (cookies.get('storehouseDisplayItems') == null || Object.keys(cookies.get('storehouseDisplayItems')).length != Object.keys(storehouseDisplayItems).length) {
        // 表示項目が更新された場合、過去のcookieを引き継ぐ
        for (const key in storehouseDisplayItems) {
          if (cookies.get('storehouseDisplayItems') == null || cookies.get('storehouseDisplayItems')[key] == null) {
            storehouseDisplayItems[key] = true
          } else {
            storehouseDisplayItems[key] = cookies.get('storehouseDisplayItems')[key]
          }
        }
        state.storehouseDisplayItems = storehouseDisplayItems
        cookies.set('storehouseDisplayItems', JSON.stringify(state.storehouseDisplayItems), '50y')
      }
      return cookies.get('storehouseDisplayItems')
    },
    async defaultSetShipperDisplayItems(state, getters) {
      const loginStatus = await getters.getShipperLoginState
      const { cookies } = useCookies();

      let shipperDisplayItems = {
        inventory_shortage_flg: true,
        comment_flg: true,
        ledger_no: true,
        management_no: true,
        admin: true,
        shipper_manager_name: true,
        input_date: true,
        input_time: true,
        vehicle_type: true,
        shipment_date: true,
        due_date: true,
        delivery_time: true,
        product_item_id: true,
        product_name: true,
        product_number: true,
        quantity: true,
        unit_name: true,
        warehouse_place: true,
        vehicle: true,
        delivery_address: true,
        consignee: true,
        contact_address: true,
        remarks: true,
        driver_flg: true,
        file_flg: true,
      }
      if (loginStatus.dmm) {
        delete shipperDisplayItems.file_flg;
        shipperDisplayItems.serial_flg = true;
        shipperDisplayItems.file_flg = true;
      }

      if (cookies.get('shipperDisplayItems') == null || Object.keys(cookies.get('shipperDisplayItems')).length != Object.keys(shipperDisplayItems).length) {
        state.shipperDisplayItems = shipperDisplayItems
        cookies.set('shipperDisplayItems', JSON.stringify(state.shipperDisplayItems), '50y')
      }
      return cookies.get('shipperDisplayItems')
    },
    getSearchParam(state) {
      return state.searchParam;
    },
    getShipperSearchParam(state) {
      return state.shipperSearchParam;
    },
    getStorehouseSearchParam(state) {
      return state.storehouseSearchParam;
    },
  },
  mutations: {
    token(state, token) {
      state.token = token;
      const { cookies } = useCookies();
      cookies.set('__jwt_token_admin', token);
    },
    setErrors(state, errors) {
      if (!errors) {
        errors = {};
      }
      state.errors = errors;
    },
    setFcDisplayItems(state, fcDisplayItems) {
      const { cookies } = useCookies();
      state.fcDisplayItems = fcDisplayItems;
      cookies.set('fcDisplayItems', JSON.stringify(state.fcDisplayItems), '50y');
    },
    setFcStorehouseDisplayItems(state, fcStorehouseDisplayItems) {
      const { cookies } = useCookies();
      state.fcStorehouseDisplayItems = fcStorehouseDisplayItems;
      cookies.set('fcStorehouseDisplayItems', JSON.stringify(state.fcStorehouseDisplayItems), '50y');
    },
    setStorehouseDisplayItems(state, storehouseDisplayItems) {
      const { cookies } = useCookies();
      state.storehouseDisplayItems = storehouseDisplayItems;
      cookies.set('storehouseDisplayItems', JSON.stringify(state.storehouseDisplayItems), '50y');
    },
    setShipperDisplayItems(state, shipperDisplayItems) {
      const { cookies } = useCookies();
      state.shipperDisplayItems = shipperDisplayItems;
      cookies.set('shipperDisplayItems', JSON.stringify(state.shipperDisplayItems), '50y');
    },
    resetLoginState(state) {
      state.loginState = '';
    },
    resetShipperLoginState(state) {
      state.ShipperloginState = '';
    },
    setSearchParam(state, {searchParam, property}) {
      state.searchParam[property] = searchParam;
    },
    setShipperSearchParam(state, {searchParam, property}) {
      state.shipperSearchParam[property] = searchParam;
    },
    setStorehouseSearchParam(state, {searchParam, property}) {
      state.storehouseSearchParam[property] = searchParam;
    },
  },
  actions: {
    token(state, data) {
      state.commit('token', data);
    },
    setErrors(state, errors) {
      state.commit('setErrors', errors);
    },
    setFcDisplayItems(state, fcDisplayItems) {
      state.commit('setFcDisplayItems', fcDisplayItems);
    },
    setFcStorehouseDisplayItems(state, fcStorehouseDisplayItems) {
      state.commit('setFcStorehouseDisplayItems', fcStorehouseDisplayItems);
    },
    setStorehouseDisplayItems(state, storehouseDisplayItems) {
      state.commit('setStorehouseDisplayItems', storehouseDisplayItems);
    },
    setShipperDisplayItems(state, shipperDisplayItems) {
      state.commit('setShipperDisplayItems', shipperDisplayItems);
    },
    resetLoginState(state) {
      state.commit('resetLoginState');
    },
    resetShipperLoginState(state) {
      state.commit('resetShipperLoginState');
    },
    setSearchParam(state, {searchParam, property}) {
      state.commit('setSearchParam', {searchParam: searchParam, property: property});
    },
    setShipperSearchParam(state, {searchParam, property}) {
      state.commit('setShipperSearchParam', {searchParam: searchParam, property: property});
    },
    setStorehouseSearchParam(state, {searchParam, property}) {
      state.commit('setStorehouseSearchParam', {searchParam: searchParam, property: property});
    },
  },
  modules: {
  }
})
