import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/FcManager/login.vue'),
  },
  {
    path: '/admin/top',
    component: () => import('../views/FcMain/top.vue')
  },
  {
    path: '/admin/logout',
    component: () => import('../views/FcManager/logout.vue')
  },

  {
    path: '/shipper/login',
    component: () => import('../views/Shipper/login.vue')
  },
  {
    path: '/shipper/top',
    component: () => import('../views/Shipper/top.vue')
  },
  {
    path: '/shipper/logout',
    component: () => import('../views/Shipper/logout.vue')
  },

  {
    path: '/warehouse/login',
    component: () => import('../views/Storehouse/login.vue')
  },
  {
    path: '/storehouse/top',
    component: () => import('../views/Storehouse/top.vue')
  },
  {
    path: '/storehouse/logout',
    component: () => import('../views/Storehouse/logout.vue')
  },

  // FC
  {
    path: '/fc_manager',
    component: () => import('../views/FcManager/index.vue')
  },
  {
    path: '/fc_manager/edit',
    component: () => import('../views/FcManager/edit.vue')
  },
  {
    path: '/fc_manager/edit/detail/:id',
    component: () => import('../views/FcManager/edit.vue')
  },

  // division
  {
    path: '/division',
    component: () => import('../views/Division/index.vue')
  },
  {
    path: '/division/edit',
    component: () => import('../views/Division/edit.vue')
  },
  {
    path: '/division/edit/detail/:id',
    component: () => import('../views/Division/edit.vue')
  },

  // shipper
  {
    path: '/shipper',
    component: () => import('../views/Shipper/index.vue')
  },
  {
    path: '/shipper/edit',
    component: () => import('../views/Shipper/edit.vue')
  },
  {
    path: '/shipper/edit/detail/:id',
    component: () => import('../views/Shipper/edit.vue')
  },
  {
    path: '/shipper/warehousing',
    children: [
      {
        path: '',
        component: () => import('../views/Shipper/Warehousing/list.vue'),
      },
      {
        path: 'register',
        component: () => import('../views/Shipper/Warehousing/register.vue'),
      },
      {
        path: 'edit/:id',
        component: () => import('../views/Shipper/Warehousing/edit.vue'),
      },
    ],
  },
  {
    path: '/shipper/shipment',
    children: [
      {
        path: 'register',
        component: () => import('../views/Shipper/Shipment/register.vue'),
      },
    ],
  },

  // shipper/account
  {
    path: '/shipper/account',
    component: () => import('../views/LoginShipper/index.vue')
  },
  {
    path: '/shipper/account/edit',
    component: () => import('../views/LoginShipper/edit.vue')
  },
  {
    path: '/shipper/account/edit/detail/:id',
    component: () => import('../views/LoginShipper/edit.vue')
  },


  // storehouse
  {
    path: '/storehouse',
    component: () => import('../views/Storehouse/index.vue')
  },
  {
    path: '/storehouse/edit',
    component: () => import('../views/Storehouse/edit.vue')
  },
  {
    path: '/storehouse/edit/detail/:id',
    component: () => import('../views/Storehouse/edit.vue')
  },

  {
    path: '/storehouse/warehousing',
    children: [
      {
        path: '',
        component: () => import('../views/Storehouse/Warehousing/list.vue'),
      },
      {
        path: 'edit/:id',
        component: () => import('../views/Storehouse/Warehousing/edit.vue'),
      },
    ],
  },
  {
    path: '/storehouse/stock',
    children: [
      {
        path: '',
        component: () => import('../views/Storehouse/Stock/index.vue'),
      },
    ],
  },

  // storehouse/account
  {
    path: '/storehouse/account',
    component: () => import('../views/LoginStorehouse/index.vue')
  },
  {
    path: '/storehouse/account/edit',
    component: () => import('../views/LoginStorehouse/edit.vue')
  },
  {
    path: '/storehouse/account/edit/detail/:id',
    component: () => import('../views/LoginStorehouse/edit.vue')
  },

  // product
  {
    path: '/product',
    component: () => import('../views/Product/index.vue')
  },
  {
    path: '/product/edit',
    component: () => import('../views/Product/edit.vue')
  },
  {
    path: '/product/edit/detail/:id',
    component: () => import('../views/Product/edit.vue')
  },

  // unit
  {
    path: '/unit',
    component: () => import('../views/Unit/index.vue')
  },
  {
    path: '/unit/edit',
    component: () => import('../views/Unit/edit.vue')
  },
  {
    path: '/unit/edit/detail/:id',
    component: () => import('../views/Unit/edit.vue')
  },

  // delivery
  {
    path: '/delivery',
    component: () => import('../views/Delivery/index.vue')
  },
  {
    path: '/delivery/edit',
    component: () => import('../views/Delivery/edit.vue')
  },
  {
    path: '/delivery/edit/detail/:id',
    component: () => import('../views/Delivery/edit.vue')
  },

  // transport
  {
    path: '/transport',
    component: () => import('../views/Transport/index.vue')
  },
  {
    path: '/transport/edit',
    component: () => import('../views/Transport/edit.vue')
  },
  {
    path: '/transport/edit/detail/:id',
    component: () => import('../views/Transport/edit.vue')
  },

  // truck
  {
    path: '/truck',
    component: () => import('../views/Truck/index.vue')
  },
  {
    path: '/truck/edit',
    component: () => import('../views/Truck/edit.vue')
  },
  {
    path: '/truck/edit/detail/:id',
    component: () => import('../views/Truck/edit.vue')
  },

  // remark
  {
    path: '/remark',
    component: () => import('../views/Remark/index.vue')
  },
  {
    path: '/remark/edit',
    component: () => import('../views/Remark/edit.vue')
  },
  {
    path: '/remark/edit/detail/:id',
    component: () => import('../views/Remark/edit.vue')
  },

  // ledger_number
  {
    path: '/ledger_number',
    component: () => import('../views/LedgerNumber/index.vue')
  },
  {
    path: '/ledger_number/edit',
    component: () => import('../views/LedgerNumber/edit.vue')
  },
  {
    path: '/ledger_number/edit/detail/:id',
    component: () => import('../views/LedgerNumber/edit.vue')
  },

  {
    path: '/warehousing',
    name: 'warehousing',
    children: [
      {
        path: '',
        name: 'warehousing-top',
        component: () => import('../views/warehousing/list.vue'),
      },
      {
        path: 'register',
        name: 'warehousing-new',
        component: () => import('../views/warehousing/register.vue'),
      },
      {
        path: 'edit/:id',
        name: 'warehousing-edit',
        component: () => import('../views/warehousing/edit.vue'),
      },
    ],
  },
  {
    path: '/shipment',
    name: 'shipment',
    children: [
      {
        path: 'register',
        component: () => import('../views/Shipment/register.vue'),
      },
      {
        path: 'import/register',
        name: 'shipment-top',
        component: () => import('../views/Shipment/Import/register.vue'),
      },
    ],
  },
  {
    path: '/request',
    name: 'request',
    children: [
      {
        path: 'shipment/:id',
        name: 'request-top',
        component: () => import('../views/Request/shipment.vue'),
      },
      {
        path: 'delivery/:id',
        name: 'request-edit',
        component: () => import('../views/Request/delivery.vue'),
      },
    ],
  },
  {
    path: '/stock',
    name: 'stock',
    children: [
      {
        path: '',
        name: 'stock-top',
        component: () => import('../views/Stock/index.vue'),
      },
    ],
  },
  {
    path: '/loading_and_unloading',
    name: 'loading_and_unloading',
    children: [
      {
        path: '',
        name: 'loading_and_unloading-top',
        component: () => import('../views/LoadingAndUnloading/index.vue'),
      },
    ],
  },
  {
    path: '/admin/storehouse',
    component: () => import('../views/FCStorehouse/index.vue')
  },
  {
    path: '/admin/storehouse/delivery/:id',
    component: () => import('../views/FCStorehouse/delivery.vue')
  },
  {
    path: '/admin/storehouse/shipment/:id',
    component: () => import('../views/FCStorehouse/shipment.vue')
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 }
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

export default router
